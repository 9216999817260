<template>
  <div>
    <div class="columns is-multiline" id="legal-status">
      <div class="column is-12">
        <p class="title is-5 has-text-dark">
          <span>Legal status</span>
        </p>
      </div>
      <div class="column is-12">
        <Risk :data="data.highRiskRecordDetails" />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Finance :data="data.finance" />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Stolen
          :data="{ stolenVehicleDetails: data.stolenVehicleDetails, stolen }"
        />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Imported :data="data.vehicleRegistration" />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Exported :data="data.vehicleRegistration" />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <p class="title is-5 has-text-dark">
          <span>Condition report</span>
        </p>
      </div>
      <div class="column is-12">
        <Condition :data="condition" />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Scrapped :data="data.vehicleRegistration" />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Vic id="vic" :data="data.vehicleIDCheckData" />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <p class="title is-5 has-text-dark">
          <span>Owner influence</span>
        </p>
      </div>
      <div class="column is-12" v-if="data.dvla">
        <Tax :data="data.dvla" />
      </div>
      <div class="column is-12" v-if="data.dvla">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12" v-if="data.dvla">
        <Mot :data="{ ...data.dvla, firstReg: specs.firstReg }" />
      </div>
      <div class="column is-12" v-if="data.dvla">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Plates
          v-bind="{ type96, voidVrm, noDvlaRegistration }"
          :data="data.plateChangeDetails"
        />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Colours
          id="colours"
          :data="data.colourChangesDetails"
          :current="data.vehicleRegistration.colour"
        />
      </div>
      <div class="column is-12" v-if="data.mileageDetails && $experian">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div
        id="mileage"
        class="column is-12"
        v-if="data.mileageDetails && $experian"
      >
        <Mileage
          v-bind="{ mileageData, mileage }"
          :data="data.mileageDetails"
        />
      </div>
      <div class="column is-12" v-if="$experian">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12" v-if="$experian">
        <Vmc
          v-bind="{ vrm, vin, mileage, hasVmc }"
          data-test-id="autocheck-vmc"
        />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12">
        <Keepers :data="data.keeperChangesDetails" />
      </div>
      <div v-if="hasFuelData" class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div v-if="hasFuelData" class="column is-12">
        <div class="columns is-multiline is-mobile">
          <span class="column">
            <span class="title is-5 has-text-dark">Fuel economy</span>
          </span>
          <span class="column is-narrow">
            <a class="title is-6 has-text-info" @click="exportCertificate">
              Export certificate
            </a>
          </span>
          <div class="column is-12">
            <Fuel
              :data="{
                vehicleExiseDutyData: data.vehicleExiseDutyData,
                combinedMpg: data.performanceAndConsumptionData.combinedMpg,
                vehicleRegistration: data.vehicleRegistration,
                performanceAndConsumptionData:
                  data.performanceAndConsumptionData,
                fuel: data.vehicleRegistration.fuel
              }"
              ref="fuel"
            />
          </div>
        </div>
      </div>
      <div class="column is-12" v-if="cazana">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12" v-if="cazana">
        <Cazana
          v-bind="{ vrm, description, mileage, date, data: data.cazana }"
        />
      </div>
      <div class="column is-12">
        <hr class="hr is-marginless has-background-soft" />
      </div>
      <div class="column is-12" v-if="$experian">
        <p class="title is-5 has-text-dark">
          <span>Data guarantee</span>
        </p>
      </div>
      <div class="column is-12" v-if="$experian">
        <Guarantee :data="request" />
      </div>
      <div class="column is-12">
        <p class="title is-5 has-text-dark">
          <span>Previous checks</span>
        </p>
      </div>
      <div class="column is-12">
        <PreviousSearches v-bind="{ previousSearches }" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { downloadFuelEconomyPdf } from '../services/pdfs'

export default {
  name: 'AutoCheckResponse',
  components: {
    Risk: () => import('../components/AutoCheckHighRisk'),
    Finance: () => import('../components/AutoCheckFinance'),
    Stolen: () => import('../components/AutoCheckStolen'),
    Imported: () => import('../components/AutoCheckImported'),
    Exported: () => import('../components/AutoCheckExported'),
    Condition: () => import('../components/AutoCheckCondition'),
    Scrapped: () => import('../components/AutoCheckScrapped'),
    Vic: () => import('../components/AutoCheckVIC'),
    Plates: () => import('../components/AutoCheckPlateChange'),
    Colours: () => import('../components/AutoCheckColourChange'),
    Mileage: () => import('../components/AutoCheckMileage'),
    Vmc: () => import('../components/AutoCheckVMC'),
    Keepers: () => import('../components/AutoCheckKeeperChange'),
    Fuel: () => import('../components/AutoCheckFuel'),
    Guarantee: () => import('../components/AutoCheckGuarantee'),
    Cazana: () => import('../components/AutoCheckCazana'),
    Mot: () => import('../components/AutoCheckMot'),
    Tax: () => import('../components/AutoCheckTax'),
    PreviousSearches: () => import('../components/AutoCheckPreviousSearches')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['hasVmc']),
    ...mapGetters('autocheck', [
      'vrm',
      'vin',
      'description',
      'request',
      'capToken',
      'mileage',
      'cazana',
      'mileageData',
      'date',
      'type96',
      'voidVrm',
      'stolen',
      'noDvlaRegistration',
      'condition',
      'specs',
      'previousSearches',
      'hasFuelData'
    ])
  },
  methods: {
    exportCertificate() {
      this.$mxp.track('autocheck_download_fuel_certificate_PDF')
      downloadFuelEconomyPdf()
    }
  }
}
</script>
